$primary-black: #404041;
$primary-white: #f8f8f8;
$primary-red: #cf2b32;
$primary-green: #82bc00;
$primary-blue: #3294d2;
$primary-grey: #707071;

$secondary-black: #333;
$secondary-white: #f5f5f5;
$secondary-red: #ea4b4b;
$secondary-green: #648b1a;
$secondary-blue: #3c5064;
$secondary-grey: #575759;
$secondary-orange: #FF9800;
$secondary-grey:#808080;

$tertiary-black: #666;
$tertiary-white: #f2f3f5;
$tertiary-red: #F44336;
$tertiary-grey: #d3d3d3;
$tertiary-green: #00a469;

$shaded-red: #d13024;
$shaded-green: #23b310;

$error-red: #d03238;
$dark-green: #007e49;

$black: #000;
$white: #fff;
$blue: #1471ac;
$indigo: #6610f2;
$purple: #8156a5;
$pink: #d63384;
$red: #d03238;
$orange: #e06e27;
$green: #008b00;
$teal: #20c997;
$cyan:#0dcaf0;
$sky-blue: #00b2e2;
$grey: #ccc;
$light-grey: #f2f2f2;
$yellow: #ffc107;
$grey: #3c5064a6;

/* :root {
  --light-grey: #f2f2f2;
} */

$theme-colors: (
  "primary":    $primary-green,
  "secondary":  $primary-white,
  "success":    $tertiary-green,
  "info":       $secondary-blue,
  "warning":    $secondary-orange,
  "danger":     $secondary-red,
  "light":      $secondary-white,
  "dark":       $secondary-black
);

$colors: (
  "blue":       $blue,
  "indigo":     $indigo,
  "purple":     $purple,
  "pink":       $pink,
  "red":        $red,
  "orange":     $orange,
  "yellow":     $yellow,
  "green":      $green,
  "teal":       $teal,
  "cyan":       $cyan,
  "white":      $white,
  "grey":       $grey
) !default;
