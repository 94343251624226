@import "../../assets/styles/imports/global";

.dnd-sequence-wrap {
  .list-group-item {
    @include flex(flex, space-between, center, nowrap);
    border: 1px solid rgba($color: $secondary-grey, $alpha: 0.4);
    border-radius: 4px !important;
    padding: 0;
    margin-bottom: 16px;
    background-color: $secondary-white;
    + .list-group-item {
      border: 1px solid rgba($color: $secondary-grey, $alpha: 0.4);
    }
    .list-holder {
      width: 44px;
      padding: 8px;
      @include flex(flex, center, center, nowrap);
      border-right: 1px solid rgba($color: $secondary-grey, $alpha: 0.4);
      img {
        width: 24px;
      }
    }
    .text-wrap {
      width: calc(100% - 54px);
      padding: 0 8px;
    }
  }
}
