/*
Project Name : SANMATEO CMS ;
Author: Damacloid;
Developed By: Manish Sharma;
Rights: Reserved;
Date: 23-01-2022
*/


@import './imports/fonts';
@import './imports/icomoon';
@import './imports/variable';
@import './imports/mixins';

@import "../../../node_modules/bootstrap/scss/bootstrap.scss";
@import "../../../node_modules/react-big-calendar/lib/css/react-big-calendar.css";


@import './imports/reset';
@import './imports/common';
@import './imports/card';
@import './imports/_form';
@import './imports/calendar';
