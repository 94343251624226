@import "../../assets/styles/imports/global";

.avtar-wrap {
  display: inline-block;
  img {
    background-color: $secondary-blue;
    border: 1px solid $primary-green;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
