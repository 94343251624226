.btn-remove {
  width: auto;
  border: none;
  outline: none;
  background: none;
  color: #d03238;
  font-weight: 500;
  margin-left: -55px;
  text-decoration: underline;
  text-transform: capitalize;
}
