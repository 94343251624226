.form-wrap {
  .form-group {
    margin-bottom: 24px;
  }
}

.form-group {
  label:not(.btn) {
    color: rgba($color: $secondary-blue, $alpha: 0.8);
    font-size: 18px;
    margin-bottom: 14px;
    &.form-character-limit {
      @include flex(flex, space-between, center, nowrap);
      span {
        font-size: 12px;
        color: rgba($color: $tertiary-black, $alpha: 0.8);
      }
    }
  }
  &.form-group-inline {
    @include flex(flex, flex-start, center, nowrap);
    .form-label {
      margin-right: 34px;
      white-space: nowrap;
      min-width: 100px;
    }
  }
  &.form-group-input-inline {
    @include flex(flex, flex-start, center, nowrap);
    .form-label {
      margin-right: 34px;
      white-space: nowrap;
      min-width: 100px;
      margin-bottom: 0;
    }
  }
}

.label-name {
  font-size: 18px;
  color: rgba(60,80,100,0.8);
}

// input
.form-control-wrap {
  width: 100%;
}
.form-control,
.picker-input__text {
  font-size: 16px;
  line-height: 1.4;
  width: 100%;
  min-height: 34px;
  padding: 4px 12px;
  color: $secondary-grey;
  background-color: $white;
  background-image: none;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border-color: $tertiary-grey;
  &:focus {
    @include inputFocus();
  }
  &.form-underline {
    border: 0;
    border-bottom: 1px solid $tertiary-grey;
    &:focus {
      border-bottom: 1px solid rgba($color: $primary-blue, $alpha: 0.4);
      box-shadow: 0 1px 0px rgba($color: $primary-blue, $alpha: 0.2);
    }
  }
  &[disabled],
  &[readonly] {
    background-color: $secondary-white;
    cursor: not-allowed;
  }
  &.is-invalid {
    &:focus {
      box-shadow: none;
    }
  }
}

// checkbox
.form-check {
  margin-bottom: 8px;
  .form-check-input {
    margin-top: 0px;
    border-radius: 0;
    outline: none;
    box-shadow: none;
    border-color: rgba($color: $secondary-grey, $alpha: 0.8);
    transform: scale(1.2);
    transform-origin: left;
    margin-right: 16px;
    &:checked {
      background-color: $primary-green;
      border-color: $primary-green;
    }
    &[type="radio"] {
      border-radius: 50%;
    }
  }
  label.form-check-label {
    color: rgba($color: $secondary-blue, $alpha: 0.8);
    cursor: pointer;
    font-size: 14px;
    line-height: 1.2;
  }
}

// switch
.form-switch {
  .form-check-input {
    border-radius: 15px;
  }
}

// select
.form-select {
  padding: 4px 36px 4px 12px;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border-color: $tertiary-grey;
  &:focus {
    @include inputFocus();
  }
}

// form info text
.false-feedback {
  display: block;
  margin-top: 8px;
  font-size: 14px;
  color: $secondary-black;
}
.invalid-feedback {
  display: block;
  color: $error-red;
  margin-top: 4px;
  font-size: 12px;
  font-weight: $font-weight-600;
}
.valid {
  color: $dark-green;
  margin-top: 4px;
  font-size: 12px;
  font-weight: $font-weight-600;
}

// upload cover image
.cover-img-wrap {
  width: 85%;
  height: 150px;
  img {
    max-width: 191px;
    width: 100%;
    height: 100%;
  }
}

// file
.form-input-wrap {
  width: 100%;
  .form-attachment-wrap {
    margin-right: 16px;
  }
  .form-file-wrap {
    @include flex(flex, flex-start, flex-start, nowrap);
  }
  .form-file-control {
    @include flex(flex, flex-start, flex-start, nowrap);
    width: 91%;
    .form-file-avtar {
      width: calc(100% - 110px);
    }
    .form-file-noavtar {
      width: calc(100% - 108px);
    }
    .btn {
      padding: 6px 15px;
      border-radius: 0;
      font-size: 12px;
    }
  }
}

//Validation Error
.ErrorMsg {
  color: red;
  font-size: 14px;
  margin-bottom: 20px;
}
