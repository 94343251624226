.address {
  position: relative;
}
.location-search-input {
  width: 100%;
  height: 35px;
  padding: 6px 12px;
  font-size: 15px;
  position: relative;
  text-overflow: ellipsis;
  border: 1px solid #d3d3d3;

  &:hover,
  &:active,
  &:focus {
    border-bottom: 1px solid #bdd6ee;
  }
  &:disabled {
    cursor: not-allowed;
  }
  &::placeholder {
    opacity: 1;
    font-size: 15px;
    font-weight: 400;
    color: #808080;
  }
  &::-webkit-input-placeholder {
    color: #808080;
    font-size: 15px;
    font-weight: 400;
  }

  &::-ms-input-placeholder {
    color: #808080;
    font-size: 15px;
    font-weight: 400;
  }

  &::-ms-input-placeholder {
    color: #808080;
    font-size: 15px;
    font-weight: 400;
  }
}
.open-job-list-up {
  max-height: 150px !important;
  overflow-y: scroll;
  bottom: 30px;
  margin-top: 0px;
}
.suggestions-container {
  width: 100%;
  padding: 0;
  border-top: 0;
  z-index: 10000;
  margin-top: 5px;
  background-color: white;
  border: 1px solid #d2d2d2;
}
.suggestion {
  height: 35px;
  z-index: 100;
  font-size: 14px;
  text-align: left;
  overflow: hidden;
  line-height: 26px;
  position: relative;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 5px 5px 5px 35px;
  border-top: 1px solid #d9d9d9;
}

.autocomplete-icon {
  display: block;
  position: absolute;
  top: 7px;
  bottom: 0;
  left: 8px;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-position: center center;
}

.autocomplete-item:hover {
  background-color: #f2f2f2;
  cursor: pointer;
}

.suggestions-container::after {
  content: "";
  padding: 1px 1px 1px 0;
  height: 18px;
  box-sizing: border-box;
  text-align: right;
  display: block;
}
.close-icon {
  position: absolute;
  top: 11px;
  right: 10px;
  cursor: pointer;
  img {
    width: 14px;
  }
}
