.download-file {
  display: flex;
  width: 300px;
  padding: 10px;
  margin: 15px 0;
  border: 1px solid #82bc00;
  justify-content: space-between;
  .file-name {
    width: 100%;
    margin-right: 10px;
    border-right: 1px solid;
  }
}
.file-attachment {
  margin-top: -25px !important;
}

.no-data {
  color: #94979b;
  font-size: 20px;
  text-align: center;
  padding: 5em 0;
}
