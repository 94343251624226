@import "../../assets/styles/imports/global";

// button start

.btn-wrap {
  * {
    &:not(:last-child) {
      margin-right: 4px;
    }
  }
  a {
    text-decoration: none;
  }
}

button,
a,
label {
  &.btn {
    @include btn;
    &.btn-primary {
      color: $white;
      &:hover {
        color: $white;
      }
    }
    &.btn-secondary {
      color: $primary-green;
      &:hover {
        color: $primary-green;
        background: $secondary-white;
      }
    }
    &.btn-danger {
      color: $white;
      &:hover {
        color: $white;
      }
    }

    &.btn-outline-primary {
      color: $primary-green;
      background-color: $white;
      &:hover {
        background: $primary-white;
        color: $primary-green;
      }
      &.no-hover {
        color: $primary-green;
        background: transparent;
      }
    }

    &.btn-link {
      color: $primary-green;
      text-decoration: none;
      padding: 0;
      &:hover {
        color: $primary-green;
      }
      &.active,
      &:active {
        box-shadow: none !important;
      }
    }
    &.btn-link-danger {
      color: $secondary-red;
      text-decoration: none;
      padding: 0;
      &:hover {
        color: $secondary-red;
      }
      &.active,
      &:active {
        box-shadow: none !important;
      }
    }

    &.btn-icon {
      position: relative;
      padding: 5px 7px;
      @include flex(flex, center, center, nowrap);
      [class^="icon"] {
        font-size: 28px;
      }
    }

    &.btn-lg {
      padding: 8px 24px;
    }
    &.btn-sm {
      padding: 1px 8px;
      font-size: 12px;
      [class^="icon"] {
        font-size: 24px;
      }
    }

    &.btn-text {
      &.btn-primary {
        @include default-btn($white, $primary-green, 0);
      }
    }

    &.btn-circular {
      &.btn-light-grey {
        @include circle-btn($light-grey, $primary-green);
      }
      &.btn-white {
        @include circle-btn($white, $black);
      }
      &.btn-circular-img {
        padding: 0;
      }
    }
  }
}
// button end
