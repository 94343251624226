@import "../../assets/styles/imports/global";

.breadcrumb-wrap {
  padding: 4px 0 20px 0;
  .breadcrumb {
    margin-bottom: 0;
  }
  .breadcrumb-item {
    text-transform: uppercase;
    font-size: 16px;
    font-weight: $font-weight-600;
    color: $secondary-green;
    + .breadcrumb-item::before {
      content: "\e911";
      font-family: icomoon;
      font-size: 14px;
      color: rgba($color: $tertiary-black, $alpha: 0.4);
    }
    &.active {
      color: rgba($color: $tertiary-black, $alpha: 0.4);
      pointer-events: none;
    }
    [class^="icon"] {
      vertical-align: bottom;
    }
    a {
      font-size: 16px;
      font-weight: $font-weight-600;
      color: $secondary-green;
      text-decoration: none;
      position: relative;
      font-family: $font-semi;
      &:after {
        content: "";
        width: 100%;
        height: 1px;
        left: 0;
        bottom: 0;
        position: absolute;
      }
      &:hover {
        color: $secondary-green;
        &:after {
          background: $secondary-green;
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .breadcrumb-wrap .breadcrumb-item:nth-child(4) {
    margin-top: 20px;
  }
  .breadcrumb-wrap .breadcrumb-item:nth-child(5) {
    margin-top: 20px;
  }
  .breadcrumb-wrap .breadcrumb {
    display: flex;
    justify-content: center;
  }
}
