@import "../../assets/styles/imports/global";

header {
  /* position: fixed;
    top: 0;
    left: 0;
    width: 100%; */
  background-color: $white;
  box-shadow: 4px 7px 12px 0 rgba($color: $black, $alpha: 0.08);
  z-index: 1;
  margin-bottom: 16px;
  .nav {
    padding: 8px 0;
  }
  .logo-wrap {
    width: 300px;
    img {
      width: 100%;
    }
  }
  .nav-wrap {
    @include flex(flex, flex-end, flex-end, nowrap);
    flex-direction: column;
    .nav-link {
      color: $black;
      font-size: 16px;
      padding: 10px 0;
    }
  }
  .dropdown-nav-wrap {
    .dropdown-toggle {
      font-size: 26px;
      color: rgba($color: $secondary-blue, $alpha: 0.8);
      font-weight: $font-weight-500;
    }
    .dropdown-menu {
      right: 0 !important;
      left: auto !important;
    }
  }
}

@media (max-width: 500px) {
  .logo-wrap {
    img {
      width: 60% !important;
    }
  }

  .nav-wrap {
    font-size: 10% !important;
  }
}
