@import "../../assets/styles/imports/global";

.feature-card-wrap {
  text-align: center;
  cursor: pointer;
  margin-bottom: 24px;
  width: 100%;
  &:hover {
    .feature-icon-wrap {
      transform: scale(1.1);
    }
    .feature-title {
      h6 {
        color: $black;
      }
    }
  }
  .card-body {
    padding: 0 44px;
  }
  .feature-icon-wrap {
    @include flex(flex, center, center, nowrap);
    height: 172px;
    font-size: 80px;
    color: $primary-green;
    transition: 0.5s ease;

    .iconImage {
      transform: scale(2);
    }
  }
  .feature-title {
    border-top: 1px solid $grey;
    min-height: 93px;
    @include flex(flex, center, center, nowrap);
    h6 {
      transition: 0.5s ease;
      text-transform: uppercase;
      color: rgba($color: $secondary-blue, $alpha: 0.8);
      font-family: $font-bold;
    }
  }
}

.FullFeatureContentLayout {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.feature-content-row {
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid rgba($color: $secondary-blue, $alpha: 0.15);
  width: 100%;
  &.no-border {
    border-bottom: 0;
  }
}

.feature-content-card-wrap {
  width: 100%;
  .feature-content-title-wrap {
    margin-bottom: 24px;
    .text-wrap {
      .subtitle1 {
        font-weight: $font-weight-600;
        // margin-bottom: 20px;
        color: rgba($color: $secondary-blue, $alpha: 0.8);
      }
      .subtitle2 {
        margin-bottom: 8px;
        color: rgba($color: $secondary-blue, $alpha: 0.8);
      }
    }
  }
  .feature-content-details-wrap {
    .text-wrap {
      &:not(:last-child) {
        margin-bottom: 8px;
      }
      > * {
        color: $secondary-blue;
      }
    }
  }
  .btn-wrap {
    display: flex;
    gap: 15px;
    .btn {
      &.btn-secondary {
        &:hover {
          background: rgba($color: $tertiary-black, $alpha: 0.15);
        }
      }
    }
  }
}

.feature-icons {
  display: flex;
  margin-left: 10px;
}

.feature-icons-card {
  font-size: 25px;
  color: #82bc00;
}
.feature-icons-card img,
.feature-icon-wrap img {
  width: 40px;
  height: 50px;
  filter: invert(70%) sepia(15%) saturate(5000%) hue-rotate(40deg)
    brightness(95%) contrast(80%);
}

.feature-program-card-wrap {
  cursor: pointer;
  .card {
    box-shadow: 1px 1px rgba($color: $black, $alpha: 0.15);
  }
  .feature-content-title-wrap {
    padding: 24px 0;
  }
  .feature-icon-wrap {
    font-size: 50px;
    color: $primary-green;
    height: 100%;
    @include flex(flex, center, center, nowrap);
    border-right: 1px solid $tertiary-grey;
    padding-right: 12px;
  }
  &.feature-program-count {
    position: relative;
    padding-left: 32px;
    &:after {
      content: "";
      @include transformY;
      height: 100%;
      width: 1px;
      left: 9px;
      background: rgba($color: $secondary-grey, $alpha: 0.3);
      z-index: 0;
    }
    .program-count {
      position: absolute;
      left: -36px;
      top: 12px;
      width: 25px;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $tertiary-white;
      color: $secondary-black;
      z-index: 1;
      font-size: 16px;
    }
  }
}

.FeatureCardCentreAlign {
  display: flex;
  align-items: center;
}

@media (max-width: 500px) {
  .FetureContentBox {
    width: 90%;
  }
  .feature-icons {
    width: auto;
  }
}
