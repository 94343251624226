@mixin font-face($style-name, $file, $family) {
  $filepath: "../../fonts/" + $family + "/" + $file;
  @font-face {
    font-family: "#{$style-name}";
    src: url($filepath + ".eot");
    src: url($filepath + ".eot?#iefix") format("embedded-opentype"),
      url($filepath + ".ttf") format("truetype"),
      url($filepath + ".svg#" + $style-name + "") format("svg");
  }
}

@include font-face("icomoon", "icomoon", "icomoon");

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak-as: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-FoodPantries-Icon:before {
  content: "\1f5cc";
}
.icon-Suicide-Icon:before {
  content: "\e93f";
}
.icon-Funding-Icon:before {
  content: "\e940";
}
.icon-Meeting-Icon:before {
  content: "\e92c";
}
.icon-Training-Icon:before {
  content: "\e934";
}
.icon-FoodResources-Icon:before {
  content: "\e935";
}
.icon-FireDep-Icon:before {
  content: "\e936";
}
.icon-Police-Icon:before {
  content: "\e937";
}
.icon-Ambulance-Icon:before {
  content: "\e938";
}
.icon-Veteran-Icon:before {
  content: "\e939";
}
.icon-Bills-Icon:before {
  content: "\e93a";
}
.icon-HomeRepair-Icon:before {
  content: "\e93b";
}
.icon-Rental-Icon:before {
  content: "\e93c";
}
.icon-FoodPantries-Icon-1:before {
  content: "\e93d";
}
.icon-Reading-Icon:before {
  content: "\e928";
}
.icon-PublicSafety-Icon:before {
  content: "\e92a";
}
.icon-Cremation-Icon:before {
  content: "\e92b";
}
.icon-Veteran_Icon:before {
  content: "\31";
}
.icon-Veteran_Icon1:before {
  content: "\e933";
}
.icon-DownPayment-Icon:before {
  content: "\e92d";
}
.icon-MobilePantries_Icon:before {
  content: "\e92e";
}
.icon-Weather-Icon:before {
  content: "\e930";
}
.icon-Location-Icon:before {
  content: "\e931";
}
.icon-Shelter-Icon:before {
  content: "\e932";
}
.icon-Community-Icon:before {
  content: "\e900";
}
.icon-Partners-Icon:before {
  content: "\e902";
}
.icon-Emergency-Icon:before {
  content: "\e903";
}
.icon-Finanacial-Icon:before {
  content: "\e904";
}
.icon-HomeLess-Icon:before {
  content: "\e905";
}
.icon-Newsletter-Icon:before {
  content: "\e901";
}
.icon-View:before {
  content: "\e906";
}
.icon-Delete-2:before {
  content: "\e907";
}
.icon-Event:before {
  content: "\e908";
}
.icon-Edit:before {
  content: "\e909";
}
.icon-Classroom_positions:before {
  content: "\e90a";
}
.icon-slider:before {
  content: "\e90b";
}
.icon-Location:before {
  content: "\e90c";
}
.icon-Student-1:before {
  content: "\e90d";
}
.icon-Home:before {
  content: "\e90e";
}
.icon-Duplicate:before {
  content: "\e90f";
}
.icon-left-arrow:before {
  content: "\e910";
}
.icon-right-arrow:before {
  content: "\e911";
}
.icon-push_notification:before {
  content: "\e912";
}
.icon-awareness-ribbon:before {
  content: "\e913";
}
.icon-blackboard:before {
  content: "\e914";
}
.icon-circle-with-plus:before {
  content: "\e915";
}
.icon-lab-flask:before {
  content: "\e916";
}
.icon-man:before {
  content: "\e917";
}
.icon-pregnant-woman:before {
  content: "\e918";
}
.icon-monetization_on:before {
  content: "\e919";
}
.icon-pushpin:before {
  content: "\e91a";
}
.icon-settings:before {
  content: "\e91b";
}
.icon-book1:before {
  content: "\e91c";
}
.icon-notebook:before {
  content: "\e91d";
}
.icon-clipboard-list:before {
  content: "\e91e";
}
.icon-clipboard:before {
  content: "\e91f";
}
.icon-info:before {
  content: "\e920";
}
.icon-information:before {
  content: "\e921";
}
.icon-sitemap:before {
  content: "\e922";
}
.icon-document:before {
  content: "\e923";
}
.icon-export-outline:before {
  content: "\e924";
}
.icon-export:before {
  content: "\e925";
}
.icon-download:before {
  content: "\e926";
}
.icon-profile-male:before {
  content: "\e927";
}
.icon-heart:before {
  content: "\f004";
}
.icon-user:before {
  content: "\f007";
}
.icon-home:before {
  content: "\f015";
}
.icon-book:before {
  content: "\f02d";
}
.icon-arrow-down:before {
  content: "\f063";
}
.icon-eye:before {
  content: "\f06e";
}
.icon-briefcase:before {
  content: "\f0b1";
}
.icon-users:before {
  content: "\f0c0";
}
.icon-legal:before {
  content: "\f0e3";
}
.icon-user-md:before {
  content: "\f0f0";
}
.icon-stethoscope:before {
  content: "\f0f1";
}
.icon-suitcase:before {
  content: "\f0f2";
}
.icon-file-text-o:before {
  content: "\f0f6";
}
.icon-frown:before {
  content: "\f119";
}
.icon-puzzle-piece:before {
  content: "\f12e";
}
.icon-ios-hand:before {
  content: "\f190";
}
.icon-graduation-cap:before {
  content: "\f19d";
}
.icon-mortar-board:before {
  content: "\f19e";
}
.icon-bus:before {
  content: "\f207";
}
.icon-Bus:before {
  content: "\f208";
}
.icon-file-document:before {
  content: "\f219";
}
.icon-file-export:before {
  content: "\f21d";
}
.icon-heartbeat:before {
  content: "\f21e";
}
.icon-intersex:before {
  content: "\f224";
}
.icon-food:before {
  content: "\f25a";
}
.icon-wheelchair-alt:before {
  content: "\f29b";
}
.icon-gender-female:before {
  content: "\f29c";
}
.icon-blind:before {
  content: "\f29d";
}
.icon-handshake:before {
  content: "\f2b5";
}
.icon-human:before {
  content: "\f2e6";
}
.icon-human-male-female:before {
  content: "\f2e8";
}
.icon-clipboard-account:before {
  content: "\f46d";
}
.icon-file-medical:before {
  content: "\f477";
}
.icon-ios-people:before {
  content: "\f47c";
}
.icon-notes-medical:before {
  content: "\f481";
}
.icon-pills:before {
  content: "\f484";
}
.icon-hand-holding-heart:before {
  content: "\f4be";
}
.icon-hands:before {
  content: "\f4c2";
}
.icon-user-graduate:before {
  content: "\f501";
}
.icon-user-tie:before {
  content: "\f508";
}
.icon-broom:before {
  content: "\f51a";
}
.icon-chalkboard-teacher:before {
  content: "\f51c";
}
.icon-palette:before {
  content: "\f53f";
}
.icon-school:before {
  content: "\f549";
}
.icon-bus-alt:before {
  content: "\f55e";
}
.icon-passport:before {
  content: "\f5ab";
}
.icon-brain:before {
  content: "\f5dc";
}
.icon-star-of-life:before {
  content: "\f621";
}
.icon-teeth:before {
  content: "\f62e";
}
.icon-campground:before {
  content: "\f6bb";
}
.icon-alarm-light:before {
  content: "\f78e";
}
.icon-tools:before {
  content: "\f7d9";
}
.icon-fire-alt:before {
  content: "\f7e4";
}
.icon-clover:before {
  content: "\f815";
}
.icon-finance:before {
  content: "\f81e";
}
.icon-clipboard-text-outline:before {
  content: "\fa37";
}
.icon-human-female-boy:before {
  content: "\fa58";
}
.icon-human-male-boy:before {
  content: "\fa5b";
}
.icon-human-male-male:before {
  content: "\fa5d";
}
.icon-account-child:before {
  content: "\fa88";
}
.icon-home-city:before {
  content: "\fcf1";
}
.icon-mother-nurse:before {
  content: "\fcfd";
}
