@import "../../assets/styles/imports/global";

.paginator-wrap {
  .page-item {
    &:not(:first-child) {
      .page-link {
        margin-left: 8px;
      }
    }
    &.page-prev {
      span {
        font-size: 0px;
        width: 10px;
        display: inline-block;
        &:after {
          display: block;
          content: "\E90B";
          font-family: icomoon;
          @include transformXY;
          font-size: 16px;
          color: $secondary-blue;
        }
      }
    }
    &.page-next {
      span {
        font-size: 0px;
        width: 10px;
        display: inline-block;
        &:after {
          display: block;
          content: "\E90C";
          font-family: icomoon;
          @include transformXY;
          font-size: 16px;
          color: $secondary-blue;
        }
      }
    }
    .page-link {
      border-radius: 4px;
      font-size: 16px;
      padding: 9px 15px;
      background: rgba($color: $secondary-white, $alpha: 0.5);
      box-shadow: none !important;
      color: $secondary-blue;
      z-index: 0;
    }
    &.active {
      .page-link {
        background: rgba($color: $secondary-white, $alpha: 0.5);
        border: 1px solid rgba($color: $black, $alpha: 0.5);
        color: $secondary-blue;
      }
    }
  }
}
