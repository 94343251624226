@import "../../assets/styles/imports/global";
@import "@y0c/react-datepicker/assets/styles/calendar.scss";

.form-date-wrap {
  .picker,
  .range-picker-input {
    display: block;
    width: 100%;
  }
  .picker-input {
    display: block;
    width: 100%;
  }
  .range-picker-input {
    display: flex;
  }
  &.is-invalid {
    .picker-input__text,
    .range-picker-input {
      border-color: $red;
      &:focus {
        box-shadow: none;
      }
    }
  }
  &.is-valid {
    .picker-input__text,
    .range-picker-input {
      border-color: $green;
      &:focus {
        box-shadow: none;
      }
    }
  }

  .range-picker-input__start,
  .range-picker-input__end {
    width: 100%;
  }
}

.calendar {
  &__day {
    vertical-align: middle;
    padding: 0;
    border: 1px solid transparent;
    height: 36px;
  }
  &__day--today {
    background: $secondary-white;
    &:not(.calendar__day--disabled) {
      background: rgba($color: $primary-green, $alpha: 0.5);
      border: 1px solid $primary-green;
    }
  }
  &__day--0:not(.calendar__day--disabled),
  &__day--6:not(.calendar__day--disabled) {
    color: $secondary-red;
  }
  &__day:hover:not(.calendar__day--disabled) {
    background: rgba($color: $primary-green, $alpha: 0.9);
  }

  &__day--range {
    background: rgba($color: $primary-green, $alpha: 0.3);
  }

  &__head--title {
    cursor: pointer;
  }

  &__year,
  &__month {
    &:hover {
      background: rgba($color: $primary-green, $alpha: 0.6);
    }
  }
}

.calendar__head,
.calendar__day--start,
.calendar__day--end,
.calendar__day--selected {
  background: $primary-green !important;
}
.calendar__panel--today {
  background: rgba($color: $primary-green, $alpha: 0.3);
}
