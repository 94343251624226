@import "./variable.scss";

.container {
  max-width: 1310px;
  height: 100%;
}

.bannerName {
  font-size: 18px;
}

.text-wrap {
  display: flex;
  flex-direction: column;
  gap: 14px;
  color: gray;
  word-break: break-all;

  .itemsDetails {
    .descriptionItem {
      color: gray;
      margin-right: 30px;
      display: flex;
      justify-content: center;
    }
    display: flex;
    flex-direction: column;
    gap: 14px;
  }
  h1 {
    font-size: 96px;
    line-height: 1.3;
    letter-spacing: 0;
    font-family: $font-reg;
    font-weight: normal;
    margin-bottom: 0;
  }
  h2 {
    font-size: 64px;
    line-height: 1.3;
    letter-spacing: 0;
    font-family: $font-reg;
    font-weight: normal;
    margin-bottom: 0;
  }
  h3 {
    font-size: 40px;
    line-height: 1.3;
    letter-spacing: 0;
    font-family: $font-reg;
    font-weight: normal;
    margin-bottom: 0;
  }
  h4 {
    font-size: 34px;
    line-height: 1.3;
    letter-spacing: 0;
    font-family: $font-reg;
    font-weight: normal;
    margin-bottom: 0;
  }
  h5 {
    font-size: 24px;
    line-height: 1.3;
    letter-spacing: 0;
    font-family: $font-reg;
    font-weight: normal;
    margin-bottom: 0;
  }
  h6 {
    font-size: 20px;
    line-height: 1.3;
    letter-spacing: 0;
    font-family: $font-reg;
    font-weight: normal;
    margin-bottom: 0;
  }
  .subtitle1 {
    font-size: 16px;
    line-height: 1.3;
    letter-spacing: 0;
    font-family: $font-reg;
    font-weight: normal;
    margin-bottom: 0;
    margin-right: 6px;
    color: rgb(75, 75, 75) !important;
  }
  .subtitle2 {
    font-size: 16px;
    line-height: 1.3;
    letter-spacing: 0;
    font-family: $font-reg;
    font-weight: normal;
    margin-bottom: 0;
    .description {
      margin-top: 10px;
    }
  }

  .AddMarginItem {
    margin-bottom: 10px;
  }
  .subtitle3 {
    font-size: 14px;
    line-height: 1.3;
    letter-spacing: 0;
    font-family: $font-reg;
    font-weight: normal;
    margin-bottom: 0;
  }
  .caption {
    font-size: 12px;
    line-height: 1.3;
    letter-spacing: 0;
    font-family: $font-reg;
    font-weight: normal;
    margin-bottom: 0;
    text-align: right;
  }
  .textAlign {
    text-align: left;
  }
  .text-button-inline {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .main-text {
      margin-right: 16px;
    }
    .btn {
      width: auto;
    }
  }
  a {
    color: $primary-green;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  /* p {
        font-family: $font-reg;
        font-weight: normal;
        line-height: 1.3;
        letter-spacing: 0;
        margin-bottom: 0;
    } */
}

.title-wrap {
  margin-bottom: 34px;
  > * {
    line-height: 1.3;
    color: $secondary-blue;
    padding: 0 0 10px 0;
    &:first-child {
      font-weight: $font-weight-600 !important;
    }
    &:last-child {
      padding: 0;
    }
  }
  &.title-underline {
    position: relative;
    padding-bottom: 16px;
    &::before {
      content: "";
      width: 90px;
      height: 3px;
      margin: 12px 0;
      background: $secondary-blue;
      position: absolute;
      bottom: -10px;
    }
  }
}
.subtitle-wrap {
  margin-bottom: 34px;
  > * {
    line-height: 1.3;
    padding-bottom: 10px;
    font-size: 14px;
    color: rgba($color: $secondary-blue, $alpha: 0.8);
    &:last-child {
      padding-bottom: 0;
    }
  }
  .subtitle1 {
    color: $secondary-blue;
    font-weight: $font-weight-600 !important;
  }
  .subtitle3 {
    color: rgba($color: $secondary-blue, $alpha: 0.8);
    font-weight: $font-weight-500 !important;
  }
}

.cursor-pointer {
  cursor: pointer;
}

// screen start
.screen-wrap {
  margin: 16px 0 125px;
  .screen-view-row {
    &:not(:last-child) {
      padding-bottom: 24px;
      margin-bottom: 24px;
      border-bottom: 1px solid $tertiary-grey;
    }
    &.no-border {
      border-bottom: 0;
    }
  }
  .screen-view-wrap {
    padding: 22px 30px;
    background-color: $white;
    border-radius: 8px;
    border: 1px solid rgba($color: $secondary-blue, $alpha: 0.25);
    box-shadow: 0 0 16px 0 rgba($color: $black, $alpha: 0.08);
  }
  .screen-view-left-divider {
    border-left: 1px solid $tertiary-grey;
    padding-left: 24px;
  }
  .screen-title-wrap {
    .subtitle1 {
      font-size: 18px;
      // text-transform: uppercase;
      color: $secondary-blue;
      font-weight: $font-weight-600;
    }
    .caption {
      color: $secondary-blue;
      font-weight: $font-weight-700;
      white-space: nowrap;
      padding-right: 16px;
    }
  }
}

.pCategoryFlexAlign {
  display: flex;
  align-items: center;
}

.btn-wrap {
  display: flex;
  justify-content: flex-end;
}
.category-btn {
  gap: 15px;
  align-items: center;
}

// dropdown start
.dropdown-menu {
  border-radius: 4px;
  border: 1px solid rgba($color: $black, $alpha: 0.15);
  box-shadow: 0 6px 12px rgba($color: $black, $alpha: 0.18);
  padding: 0;
  overflow: hidden;
  .dropdown-item {
    padding: 10px;
    color: $secondary-blue;
    cursor: pointer;
    &:hover,
    &.active {
      background: $primary-white;
    }
  }
}
.dropdown-nav-wrap {
  .dropdown-toggle {
    @include flex(flex, flex-end, center, nowrap);
    text-transform: capitalize;
    font-size: 26px;
    color: rgba($color: $secondary-blue, $alpha: 0.8);
    font-weight: $font-weight-500;

    &::after {
      content: "";
      background: url("../../icons/menu.png") no-repeat;
      background-size: cover;
      background-position: center;
      border: 0;
      width: 40px;
      height: 40px;
      background-color: $secondary-white;
      border: 8px solid $secondary-white;
      margin-left: 16px;
    }
  }
  .dropdown-menu {
    overflow: visible;
    width: 300px;
    &::before {
      content: "";
      width: 0;
      height: 0;
      position: absolute;
      bottom: 100%;
      right: 12px;
      border-width: 0 8px 7px;
      border-style: solid;
      border-color: $secondary-blue transparent;
    }
    .dropdown-item {
      color: $white;
      font-size: 18px;
      padding: 16px;
      background: $secondary-blue;
      &:first-child {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }
      &:last-child {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
      }
      &:hover,
      &.active {
        background: rgba($color: $secondary-blue, $alpha: 0.9);
      }
    }
  }
}
// dropdown end

// accordion start
.accordion-wrap {
  width: 100%;
  .accordion-header-rowreverse {
    width: 100%;
    .accordion-button {
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
      &::after {
        margin: 0 16px 0 0;
      }
    }
  }
  .accordion-title {
    .subtitle1 {
      color: $secondary-blue;
      font-weight: $font-weight-400;
      border-bottom: 1px solid rgba($color: $black, $alpha: 0.1);
      padding-bottom: 16px;
    }
  }
  .accordion-item {
    width: 100%;
    border-color: transparent;
    box-shadow: none !important;
    border-bottom-color: rgba($color: $black, $alpha: 0.1);
  }
  .accordion-button {
    font-size: 16px;
    color: $secondary-blue;
    padding-left: 0;
    padding-right: 0;
    font-weight: $font-weight-600;
    text-transform: uppercase;
    background: $white;
    box-shadow: none !important;
    &:focus {
      background: $white;
      box-shadow: none;
    }
  }
  .accordion-header-actions {
    width: 100%;
    @include flex(flex, space-between, center, nowrap);
  }
  .accordion-body {
    padding: 8px 0 16px 0;
  }
  .accordion-border-top {
    border-top: 1px solid rgba($color: $black, $alpha: 0.1);
  }
}
// accordion end

// table start
.table-wrap {
  table {
    color: $secondary-black;
    &.table-striped {
      tbody {
        tr:nth-child(odd) {
          td {
            box-shadow: inset 0 0 0 9999px
              rgba($color: $light-grey, $alpha: 0.6);
          }
        }
      }
    }
    th {
      font-weight: $font-weight-600;
      font-size: 16px;
      vertical-align: middle;
      text-transform: uppercase;
    }
    td {
      font-size: 16px;
      line-height: 1.2;
      vertical-align: middle;
      border-top: 1px solid rgba($color: $secondary-grey, $alpha: 0.25);
    }
    > :not(:first-child) {
      border-top: 2px solid rgba($color: $secondary-grey, $alpha: 0.25);
    }
  }
  .form-check {
    margin: 0;
    .form-check-label {
      margin: 0;
      color: $secondary-black;
      font-size: 16px;
    }
  }
}
// table end

// tabs start
.tabs-wrap {
  // background: $secondary-white;
  // padding: 16px;
  // border-radius: 4px;
  overflow: hidden;
  .nav-tabs {
    border-color: rgba($color: $primary-grey, $alpha: 0.2);
    .nav-link {
      padding: 14px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      color: $secondary-black;
      font-weight: $font-weight-600;
      background: $primary-white;
      border-color: rgba($color: $primary-grey, $alpha: 0.2);
      color: $primary-green;
      font-size: 14px;
      &:hover {
        background: $secondary-white;
      }
      &:active {
        box-shadow: inset 0 3px 5px rgba($color: $black, $alpha: 0.13) !important;
      }
      &.active {
        background: $primary-green;
        color: $white;
      }
    }
  }
}
// tabs end

// crop image preview
.ReactCrop {
  img {
    width: 100%;
  }
}
// crop image preview end

.screen-justify {
  justify-content: space-between;
}

.error-msg {
  color: $secondary-red;
  font-size: 16px;
  font-weight: $font-weight-700;
  margin-bottom: 8px;
}

.title {
  font-size: 12px;
  color: $grey;
}

@media (min-width: 360px) and (max-width: 768px) {
  .subtitle1 {
    font-size: 10px;
  }
  .caption {
    font-size: 12px;
  }
  .subtitle2 {
    font-size: 4px;
  }
  .screen-wrap {
    padding: 0px 0px;
  }
  .screen-view-row {
    margin-left: -17px;
  }
  .screen-justify {
    justify-content: space-between;
  }
  .container {
    // padding: 0px;
    width: 100%;
    margin: 0;
  }
  .text-wrap {
    .subtitle1 {
      width: 60%;
      font-size: 16px;
    }
    .subtitle2 {
      width: 60%;
      font-size: 14px;
    }
  }
  .justTestme {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
}
